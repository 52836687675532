define([
    'santa-components',
    'zepto',
    'lodash',
    'react',
    'create-react-class',
    'componentsCore',
    'coreUtils',
    'verticalMenu/components/verticalMenuItem',
    'reactDOM',
    'comboBoxInput',
    'skins',
    'verticalMenu/skins/skins.json'
], function (santaComponents, $, _, React, createReactClass, componentsCore, coreUtils, verticalMenuItem, ReactDOM, comboBoxInput, skinsPackage, skinsJson) {
    'use strict';

    const cmp = createReactClass(verticalMenuItem);
    const verticalMenuItemInstance = React.createElement.bind(null, cmp);
    verticalMenuItemInstance.type = cmp;

    function onMouseClick(event) {
        const liParent = getLIParent(event.target);
        const newHoverId = liParent.id;
        const hasVisibleItems = $(liParent).find('ul')[0].children.length > 0;
        const isAlreadyHovered = newHoverId === this.state.hoverId;

        if (hasVisibleItems) {
            if (isAlreadyHovered) {
                this.setState({hoverId: null});
            } else {
                this.setState({hoverId: newHoverId});
                event.preventDefault();
            }
        } else {
            this.setState({hoverId: null});
        }
    }

    function openSubMenu(event) {
        const hoveredNodeId = getLIParent(event.target).id;
        if (hoveredNodeId !== this.state.hoverId) {
            this.setState({hoverId: hoveredNodeId});
        }
    }

    function closeSubMenu() {
        this.setState({hoverId: null});
    }

    function getLIParent(node) {
        while (node.tagName !== 'LI') {
            node = node.parentElement;
        }

        return node;
    }

    /**
     * @class components.verticalMenu
     * @extends {core.skinBasedComp}
     * @extends {componentsCore.skinInfo}
     */
    const verticalMenu = {
        displayName: 'VerticalMenu',
        mixins: [
            componentsCore.mixins.skinBasedComp,
            componentsCore.mixins.skinInfo,
            componentsCore.mixins.createChildComponentMixin,
            componentsCore.mixins.mobileDropDownMenuMixin
        ],
        propTypes: _.assign({
            isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
            isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            menuItems: santaComponents.santaTypesDefinitions.Menu.menuItems,
            currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId.isRequired,
            navigateToRenderedLink: santaComponents.santaTypesDefinitions.Navigation.navigateToRenderedLink.isRequired,
            linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo,
            previewTooltipCallback: santaComponents.santaTypesDefinitions.RenderRealtimeConfig.previewTooltipCallback,
            compData: santaComponents.santaTypesDefinitions.Component.compData,
            compProp: santaComponents.santaTypesDefinitions.Component.compProp,
            styleId: santaComponents.santaTypesDefinitions.Component.styleId,
            skin: santaComponents.santaTypesDefinitions.Component.skin,
            style: santaComponents.santaTypesDefinitions.Component.style,
            isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen,
            scale: santaComponents.santaTypesDefinitions.Component.scale,
            getTranslatedAriaLabel: santaComponents.santaTypesDefinitions.Accessibility.getTranslatedAriaLabel.isRequired
        }, santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(comboBoxInput)),

        statics: {
            compSpecificIsDomOnlyOverride: (prevProps, nextProps) =>
                nextProps.style.height === prevProps.style.height // height is passed as props to children slides

        },
        subMenuTabbableItems: false,
        getInitialState() {
            this.prevCompProps = _.cloneDeep(this.props.compProp);
            this.isMobileView = _.cloneDeep(this.props.isMobileView);
            return {
                $subMenuOpenSide: `subMenuOpenSide-${this.props.compProp.subMenuOpenSide}`,
                $itemsAlignment: `items-align-${this.props.compProp.itemsAlignment}`,
                $subItemsAlignment: `subItems-align-${this.props.compProp.itemsAlignment}`,
                hoverId: null,
                $mobile: this.props.isMobileView ? 'mobile' : 'notMobile'
            };
        },
        componentWillReceiveProps(nextProps) {
            if (nextProps.compProp.itemsAlignment !== this.prevCompProps.itemsAlignment) {
                this.setState({
                    $itemsAlignment: `items-align-${nextProps.compProp.itemsAlignment}`,
                    $subItemsAlignment: `subItems-align-${nextProps.compProp.itemsAlignment}`
                });
            }
            if (nextProps.compProp.subMenuOpenSide !== this.prevCompProps.subMenuOpenSide) {
                this.setState({$subMenuOpenSide: `subMenuOpenSide-${nextProps.compProp.subMenuOpenSide}`});
            }

            if (nextProps.isMobileView !== this.isMobileView) {
                this.isMobileView = nextProps.isMobileView;
                this.setState({$mobile: nextProps.isMobileView ? 'mobile' : 'notMobile'});
            }
            this.prevCompProps = _.cloneDeep(nextProps.compProp);
        },
        updateDOMDataAttributes() {
            const domNode = ReactDOM.findDOMNode(this);
            const params = this.getParamValues();
            this.lastParams = params;
            domNode.setAttribute('data-param-border', params.border);
            domNode.setAttribute('data-param-separator', params.separator);
            domNode.setAttribute('data-param-padding', params.padding);
        },
        componentDidMount() {
            this.updateDOMDataAttributes();
        },
        componentDidUpdate() {
            if (!_.isEqual(this.getParamValues(), this.lastParams)) {
                this.updateDOMDataAttributes();
            }
        },
        getParamValues() {
            const separatorParam = this.getParamFromDefaultSkin('separatorHeight') || this.getParamFromDefaultSkin('sepw');
            const paddingParam = this.getParamFromDefaultSkin('textSpacing');
            const borderParam = this.getParamFromDefaultSkin('brw');

            return {
                separator: separatorParam ? parseInt(separatorParam.value, 10) : 0,
                border: borderParam ? parseInt(borderParam.value, 10) : 0,
                padding: paddingParam ? parseInt(paddingParam.value, 10) : 0
            };
        },

        handleKeyDownForSubMenu(event) { // eslint-disable-line complexity
            let nextIndexToFocus;
            if (!event.shiftKey && event.key === 'Tab') { // eslint-disable-line no-mixed-operators
                nextIndexToFocus = _.indexOf(this.subMenuTabbableItems, event.target) + 1;
                if (nextIndexToFocus < this.subMenuTabbableItems.length) {
                    event.stopPropagation();
                    event.preventDefault();
                    this.subMenuTabbableItems[nextIndexToFocus].focus();
                }
            }
            if (event.shiftKey && event.key === 'Tab') { // eslint-disable-line no-mixed-operators
                event.stopPropagation();
                event.preventDefault();
                nextIndexToFocus = _.indexOf(this.subMenuTabbableItems, event.target) - 1;
                if (nextIndexToFocus < 0) {
                    this.lastFocusedMainMenuItem.focus();
                } else {
                    this.subMenuTabbableItems[nextIndexToFocus].focus();
                }
            }
        },

        handleKeyDownForMainMenu(event) {
            const shouldFocusSubMenu = !event.shiftKey && event.key === 'Tab'; // eslint-disable-line no-mixed-operators
            if (shouldFocusSubMenu) {
                const liParent = getLIParent(event.target);
                const subMenu = _.head($(liParent).find('ul'));

                if (subMenu) {
                    const tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(subMenu);
                    if (tabbableElements && tabbableElements.length > 0) {
                        event.stopPropagation();
                        event.preventDefault();

                        _.head(tabbableElements).focus();
                        this.subMenuTabbableItems = tabbableElements;
                        this.lastFocusedMainMenuItem = event.target;
                    }
                }
            }
        },

        menuItemKeyDownHandler(event) { // eslint-disable-line complexity
            if (_.includes(event.target.className, 'level0')) {
                this.handleKeyDownForMainMenu(event);
            } else {
                this.handleKeyDownForSubMenu(event);
            }
        },

        getAriaLabel() {
            const MENU_ARIA_LABEL_KEY = 'dropDownMenu_AriaLabel_TopLevel_SiteNavigation';
            return this.props.getTranslatedAriaLabel('AriaLabels', MENU_ARIA_LABEL_KEY, 'Site');
        },

        getSkinProperties() {
            const params = this.getParamValues();
            const skinExports = this.getSkinExports();
            const menuItems = this.props.menuItems;
            const itemHeight = this.props.compProp.menuItemHeight || 0;

            if (this.props.isMobileView) {
                const flatMenuItems = this.flattenMenuItems(menuItems);
                const comboBoxItems = this.convertMenuItemsToComboBoxItems.call(this, flatMenuItems, this.props.compProp.itemsAlignment);
                return {
                    '': {
                        tagName: 'nav',
                        'aria-label': this.getAriaLabel()
                    },
                    menuContainer: this.createChildComponent(
                        {options: comboBoxItems, value: this.getSelectedPage(comboBoxItems), id: 'menuContainer'},
                        'wysiwyg.viewer.components.inputs.ComboBoxInput',
                        'menuContainer',
                        {
                            onSelectionChange: this.onSelectionChange,
                            scale: this.props.scale,
                            compProp: {
                                class: 'mobileMenuContainer',
                                collectionClass: 'mobileCollection',
                                textAlignment: this.props.compProp.itemsAlignment,
                                textPadding: 20,
                                placeholder: {
                                    text: 'Choose a page',
                                    value: 'Choose a page'
                                }
                            },
                            setRuntimeCompData: _.noop,
                            setRuntimeCompProps: _.noop
                        }
                    )

                };
            }

            return {
                '': {
                    tagName: 'nav',
                    'aria-label': this.getAriaLabel(),
                    style: {
                        height: ''
                    }
                },
                menuContainer: {
                    parentConst: verticalMenuItemInstance,
                    data: menuItems,
                    skin: this.props.skin,
                    classPrefix: this.props.styleId,
                    currentUrlPageId: this.props.currentUrlPageId,
                    heights: {
                        separator: skinExports && skinExports.separatorNotIncludedInLineHeight ? 0 : params.separator,
                        line: coreUtils.verticalMenuCalculations.getLineHeight(itemHeight, params.separator, params.border, skinExports),
                        item: itemHeight
                    },
                    callbacks: {
                        click: onMouseClick.bind(this),
                        enter: openSubMenu.bind(this),
                        leave: closeSubMenu.bind(this),
                        focus: openSubMenu.bind(this),
                        blur: closeSubMenu.bind(this),
                        keyDown: this.menuItemKeyDownHandler
                    },
                    hoverId: this.state.hoverId,
                    isDesktop: !(this.props.isMobileDevice || this.props.isTabletDevice()),
                    counterClassName: 'counter',
                    itemsAlignment: this.props.compProp.itemsAlignment,
                    isExperimentOpen: this.props.isExperimentOpen
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.common.components.verticalmenu.viewer.VerticalMenu', verticalMenu);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return verticalMenu;
});
