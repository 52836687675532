define(['skins', 'verticalMenu/components/verticalMenuDomBuilder'], function (skinsPackage, domBuilder) {
    'use strict';

    const menuItem = {
        displayName: 'MenuItem',
        render() {
            const skin = domBuilder.getSkin(skinsPackage.skinsMap, this.props.skin),
                template = domBuilder.buildTemplate(skin.react[0], this.props.classPrefix);

            return domBuilder.buildDOMFromTemplate(template, this.props.data, this.props.classPrefix, this.props.currentUrlPageId, this.props.heights, this.props.hoverId, this.props.callbacks, this.props.isDesktop, this.props.counterClassName, this.props.itemsAlignment);
        }
    };

    return menuItem;
});
